function reportAbuseFields() {
  return {
    typeOfScam: {
      value: [],
      items: [
        { label: 'Phishing', key: 'Phishing' },
        { label: 'Fake ICO', key: 'Fake ICO' },
        { label: 'Ponzi scheme', key: 'Ponzi scheme' },
        { label: 'Ransomeware', key: 'Ransomeware' },
        { label: 'Other', key: 'Else' },
      ],
    },
    description: {
      value: '',
    },
    nameAbuser: {
      value: '',
    },
    link: {
      value: '',
    },
    contactAbuser: {
      value: '',
    },
    walletOrTx: {
      value: '',
    },
    files: {
      value: [],
    },
  }
}

export { reportAbuseFields }
