<template>
  <GlPageWrap>
    <div class="report-abuse-wrapper">
      <div class="report-abuse">
        <div class="report-abuse-head-text-wrapper">
          <div class="report-abuse-head-text bold-600 fs-36 m-fs-20">
            Report Abuse
          </div>
        </div>
        <div class="report-abuse-main-content-wrapper">
          <ValidationObserver
            ref="reportAbuseForm"
            v-slot="{ handleSubmit, errors }"
            class="report-abuse-main-content"
            slim
          >
            <form @submit.prevent="handleSubmit(onSubmit, errors)">
              <div>
                <GlSelect
                  v-model="formData.typeOfScam.value"
                  class="mb-4"
                  :clearable="true"
                  label="Type of scam/abuse (e.g., phishing, fake ICO, Ponzi scheme, ransomeware, etc.)"
                  label-select="label"
                  light-gray
                  min-height="44"
                  :multiple="false"
                  name="Type of scam"
                  :options="formData.typeOfScam.items"
                  placeholder="Choose type of scam"
                  rules="required"
                  searchable
                />
                <GlInput
                  v-model="formData.description.value"
                  class="mb-4"
                  :height="44"
                  is-block-error-style
                  label="Description of the incident (including relevant dates, times, and any interactions with the scammer/abuser)"
                  light-gray
                  name="Description"
                  placeholder="Add description"
                />
                <GlInput
                  v-model="formData.nameAbuser.value"
                  class="mb-4"
                  :height="44"
                  is-block-error-style
                  label="Name or alias of the suspected scammer/abuser (if known)"
                  light-gray
                  name="Name"
                  placeholder="Add name"
                />
                <GlInput
                  v-model="formData.link.value"
                  class="mb-4"
                  :height="44"
                  is-block-error-style
                  label="URLs or web addresses associated with the scam/abuse (e.g., websites, social media profiles, forum posts, etc.)"
                  light-gray
                  name="Link"
                  placeholder="Add link"
                />
                <GlInput
                  v-model="formData.contactAbuser.value"
                  class="mb-4"
                  :height="44"
                  is-block-error-style
                  label="Email addresses, phone numbers, or other contact information related to the scammer/abuser"
                  light-gray
                  name="Email address"
                  placeholder="Add email address"
                />
                <GlInput
                  v-model="formData.walletOrTx.value"
                  class="mb-4"
                  :height="44"
                  is-block-error-style
                  label="Any associated transaction IDs, wallet addresses"
                  light-gray
                  name="Text"
                  placeholder="Add text"
                />

                <div class="bold fs-18 light-grey-59-text mb-4 m-fs-14">
                  Screenshot or other evidence supporting the claim (e.g.,
                  emails, chat logs, etc.)
                </div>

                <FileUpload
                  :key="fileUploadKey"
                  ref="fileUploadRef"
                  accept="image/*,.pdf"
                  class="fileupload-no-buttonbar fileupload-hide-drag-and-drop mb-4"
                  custom-upload
                  :file-limit="fileLimit"
                  :invalid-file-limit-message="fileLimitMessage"
                  :invalid-file-type-message="fileTypeMessage"
                  :max-file-size="maxFileSize"
                  multiple
                  :show-cancel-button="false"
                  :show-upload-button="false"
                  @uploader="uploader"
                >
                  <template #empty>
                    <p>Drag and drop files to here to upload.</p>
                  </template>
                </FileUpload>
                <div
                  class="flex align-center accent-text-link pointer bold-600 width-fit-content m-fullwidth m-justify-center m-mb-4"
                  :class="{ 'upload-button--disabled': uploadButtonDisabled }"
                  @click="uploadFileButtonClick"
                >
                  <div class="flex align-center">
                    <gl-icon
                      class="mr-2"
                      :height="24"
                      link
                      name="upload-file"
                      :width="24"
                    />
                    Upload File
                  </div>
                </div>

                <div class="report-abuse-footer">
                  <div class="flex column gap-4">
                    <vue-recaptcha
                      v-show="showRecaptcha"
                      :key="recaptchaKey"
                      ref="recaptcha"
                      :sitekey="sitekey"
                      @verify="onCaptchaVerified"
                    />

                    <GlButtonPrime
                      v-show="showSubmitButton"
                      class="report-abuse-submit-button height-44 min-w-250 bold-600"
                      :loading="submitLoading"
                      title="Send"
                      @click="onSubmit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <ThankYouModal
        v-model="allDoneModal"
        :message="doneMessage"
        @confirm="allDoneModalConfirm"
      />
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions } from 'vuex'
// Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import ThankYouModal from './modals/ThankYouModal'
import GlSelect from '@/components/gl-select'
import GlInput from '@/components/gl-input'
import VueRecaptcha from 'vue-recaptcha'
import FileUpload from 'primevue/fileupload'
import GlIcon from '@/components/gl-icon'
import GlButtonPrime from '@/components/gl-button-prime'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Utils

// models
import { reportAbuseFields } from '@/pages/report-abuse/models/report-abuse-fields'

// Libs
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    VueRecaptcha,
    ValidationObserver,
    FileUpload,
    GlPageWrap,
    ThankYouModal,
    GlSelect,
    GlInput,
    GlIcon,
    GlButtonPrime,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      recaptchaKey: 1,
      fileUploadKey: 1,
      showRecaptcha: false,
      showSubmitButton: true,
      allDoneModal: false,
      fileLimitMessage: 'Maximum 20 files',
      fileTypeMessage: 'Allowed only images and pdf',
      doneMessage:
        'You report was successfully sent. We will contact you shortly.',
      formData: {},
      recaptchaScriptEle: null,
      submitLoading: false,
      filesResult: [],
      fileLimit: 20,
      maxFileSize: 10000000,
      uploadButtonDisabled: false,
    }
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITEKEY
    },
    formDataResult() {
      return this.formData
    },
  },
  created() {
    const $script = document.createElement('script')
    $script.async = true
    $script.src =
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    this.recaptchaScriptEle = document.head.appendChild($script)

    this.formData = this.reportAbuseFields()
  },
  beforeDestroy() {
    document.head.removeChild(this.recaptchaScriptEle)
  },
  methods: {
    ...mapActions('reportAbuse', ['sendReportAbuse']),
    ...mapActions('user', ['getMe']),
    reportAbuseFields,
    allDoneModalConfirm() {
      this.allDoneModal = false
    },
    async onCaptchaVerified(response) {
      if (!response) return

      const fileUpload = this.$refs.fileUploadRef
      fileUpload.upload()

      this.formData.files.value = [...this.filesResult]

      this.showSubmitButton = true
      this.submitLoading = true

      const fd = this.getFormatedFormData(this.formData)

      this.sendReportAbuse({
        formData: fd,
        recaptchaToken: response,
      })
        .then(({ success }) => {
          if (!success) return
          this.allDoneModal = true
        })
        .finally(() => {
          this.recaptchaKey++
          this.fileUploadKey++
          this.showRecaptcha = false
          this.submitLoading = false
          this.formData = this.reportAbuseFields()
          fileUpload.clearInputElement()
          this.$refs.reportAbuseForm.reset()
        })
    },
    async onSubmit() {
      const isValid = await this.$refs.reportAbuseForm.validate()
      let isFilesValid = false

      const fileUploadFiles = this.$refs.fileUploadRef.files
      if (fileUploadFiles.length <= this.fileLimit) isFilesValid = true

      if (!isValid || !isFilesValid) {
        this.scrollToError()
        return
      }

      this.showRecaptcha = true
      this.showSubmitButton = false
    },
    uploader(event) {
      this.filesResult = event.files
    },
    getFormatedFormData(data) {
      const fd = new FormData()

      fd.append(
        'typeOfScam',
        data.typeOfScam.value?.key ? data.typeOfScam.value?.key : '',
      )
      fd.append('description', data.description.value)
      fd.append('name', data.nameAbuser.value)
      fd.append('links', data.link.value)
      fd.append('contacts', data.contactAbuser.value)
      fd.append('walletOrTx', data.walletOrTx.value)
      data.files.value.forEach(element => {
        fd.append('files', element)
      })
      return fd
    },
    uploadFileButtonClick() {
      const fileUpload = this.$refs.fileUploadRef
      if (!fileUpload) return
      const fileUploadFiles = this.$refs.fileUploadRef.files
      if (fileUploadFiles.length >= this.fileLimit) {
        this.$toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Maximum 20 files',
          life: 3000,
        })
        return
      }
      fileUpload.choose()
    },
    scrollToError() {
      this.$nextTick(() => {
        const reportAbuseForm = this.$refs.reportAbuseForm.$el

        const targetElement =
          reportAbuseForm.querySelector('.gl-select__error') ||
          reportAbuseForm.querySelector('.gl-input__error') ||
          reportAbuseForm.querySelector('.p-message-error')

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      })
    },
  },
}
</script>

<style>
:root {
  --report-abuse-header-height: 120px;
}
.report-abuse-wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey-f-8);
  padding-top: var(--header-height);
  font-family: 'Montserrat', sans-serif;
}

.report-abuse {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: var(--main-content-height);
}

.report-abuse-head-text-wrapper {
  display: flex;
  justify-content: center;
}

.report-abuse-head-text {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-top: 24px;
  padding-bottom: 24px;
  height: var(--report-abuse-header-height);
}

.report-abuse-main-content-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  background: var(--white);
  box-shadow: 0px 0px 100px 0px var(--dark-transparent);
  padding: 36px 0;
}

.report-abuse-main-content {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-right: 4px;
}

.report-abuse-main-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.report-abuse .gl-select__label,
.report-abuse .gl-input__label {
  font-size: 18px;
}

.report-abuse-wrapper .thank-you-modal-message-wrapper {
  max-width: 340px;
}
.report-abuse-wrapper .vs__selected-options input {
  color: var(--light-grey-59);
}

.report-abuse-footer {
  display: flex;
  justify-content: flex-end;
}
.upload-button--disabled {
  opacity: 0.5;
  cursor: default;
}
@media (max-width: 1023px) {
  .report-abuse-main-content-wrapper {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .report-abuse {
    align-items: center;
    background: var(--grey-white);
    padding-top: 16px;
  }

  .report-abuse-head-text-wrapper {
    width: 100%;
  }
  .report-abuse-head-text {
    background: var(--white);
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--light-grey-e-f);
    border-bottom: 0;
    padding: 16px;
    height: auto;
  }

  .report-abuse-main-content-wrapper {
    width: var(--main-content-width);
    padding-top: 24px;
    background: var(--white);
    padding-bottom: 24px;
    margin-bottom: calc(30px + var(--report-banner-height));
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--light-grey-e-f);
  }

  .report-abuse-footer {
    display: block;
    margin-top: 50px;
  }
  .report-abuse-submit-button {
    width: 100%;
  }

  .report-abuse .gl-select__label,
  .report-abuse .gl-input__label {
    font-size: 14px;
  }

  .report-abuse .p-fileupload-content:not(:has(.p-fileupload-empty)) {
    padding: 0;
  }

  .report-abuse .p-fileupload-row > div {
    flex: none;
    width: unset;
  }

  .report-abuse .p-fileupload-row {
    display: grid;
    border-bottom: 1px solid var(--pale-blue);
  }
  .report-abuse .p-fileupload-row > *:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 3;
  }
  .report-abuse .p-fileupload-row > *:nth-child(2) {
    grid-column: 2 / 5;
    grid-row: 1;
  }
  .report-abuse .p-fileupload-row > *:nth-child(3) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }
  .report-abuse .p-fileupload-row > *:nth-child(4) {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }
}
</style>
